import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PLAY, PAUSE, SAVE_BOOKMARK, START_SEEK, REMOVE_PLAYER_FOCUS, PLAYER_FOCUS, UPDATE_SEEK, AUTO_PLAY_NEXT_EPISODE, EDITORIALS, WATCH_NEXT_EPISODE_DETAILS, PLAYER_SETTINGS_FOCUS, PLAYER_SETTINGS_HIDE, PLAYER_AUDIO_TRACKS } from "@connected-video-web/dstv-frontend-services/dist/actions";
import { GLOBALS } from '../../globals';
import styles from '../../base-styles/playerui.scss'
import { KeyCode } from '../../keyCodeMapping';
import PLAY_ICON from '../../Images/play_icon.png';
import PAUSE_ICON from '../../Images/pause_button.png';
import SETTINGS_ICON from '../../Images/settings_icon.png';
import SETTINGS_ICON_FOCUS from '../../Images/settings_icon_focus.png';
import { getDurationHHMMSS, setPlayerSeason, setPlayerEpisode, setPlayerEpisodeTitle, findActiveEpisode, getVodStates, autoPlayActions, filterAndSelectVariant, getLabel } from './PlayerHelper'
import { AutoPlayNextEp } from '@connected-video-web/dstv-frontend-components/dist/Components/Container/AutoPlay';
import { autoPlayEnabled, shouldEnableAutoPlayToggle, alterantiveAudioEnabled } from '@connected-video-web/dstv-frontend-services/dist/Utils/flagrHelper';
import { TvShowpageRecommendations } from '@connected-video-web/dstv-frontend-components/dist/Components/Container/TvShowpageRecommendations';
import { styledEditorial } from '../styledComponents';
import { StorageKey } from '@connected-video-web/dstv-frontend-services/dist/constants/storageKey';
import { TvPlayerSettings } from '@connected-video-web/dstv-frontend-components/dist/Components/Container/TvPlayerSettings';
import { saveToLocalStorage, retriveFromLocalStorage } from '@connected-video-web/dstv-frontend-services/dist/Utils/storageHelpers';
import { defaultBitrateData } from '@connected-video-web/dstv-frontend-services/dist/constants/defaultData';

class VodPlayerCtrl extends Component {
    constructor(props) {
        super(props);
        this.initializeVariables()
        this.registerEventListners()
        this.setProgress()
        this.showMetadata()
        this.prepareAutoNextEpisodeAsset()
        this.currentDuration = 0
    }

    initializeVariables = () => {
        this.state = getVodStates()
        this.timer = null
        this.autoHideTimer = null
        this.seekTimer = null
        this.scrubbingSpeed = 0
        this.scrubbingThreshold = 0
        this.displayHeading = ''
        this.displayEpisode = ''
        this.isTryThisVisible = false
        this.autoPlayHeading = ''
        this.autoPlayTitle = ''
        this.autoPlayNext = false
        this.autoPlayActions = autoPlayActions()
        this.state.autoPlayPopupStart = null
        this.state.autoPlayPopupEnd = null
        this.autoPlayEpisode = ''
        this.playerVariants = null
        this.setPlaybackDefault = false
        this.activeAudio = null
    }

    registerEventListners = () => {
        window.addEventListener("keydown", this.navigation)
        window.addEventListener("keyup", this.onKeyUp)
        document.getElementById('player') && document.getElementById('player').addEventListener("timeupdate", this.playerTimeUpdateEvent)
    }

    playerTimeUpdateEvent = (e) => {
        var newSecond = Math.floor(e.target.currentTime);
        if (newSecond % 1 === 0 && this.currentDuration !== newSecond) {
            this.currentDuration = newSecond
        }
    }

    resetCurrentDuration = () => {
        this.currentDuration = 0
    }

    navigation = (e) => {
        if (this.props.playbackStarted) {
            switch (KeyCode(e.keyCode)) {
                case "UP": this.handleUp(); break;
                case "DOWN": this.handleDown(); break;
                case "LEFT": this.handleLeft(); break;
                case "RIGHT": this.handleRight(); break;
                case "SELECT": this.handleSelect(KeyCode(e.keyCode)); break;
                case "BACK": this.goBack(); break;
                case "STOP": this.props.stopPlayer(); break;
                case "FAST_FWD":
                case "REWIND":
                case "PLAY":
                case "PAUSE": this.handleRemoteEvents(KeyCode(e.keyCode)); break;
                case "PLAY_PAUSE": {
                    this.showControls();
                    this.requestPlayPause();
                    break;
                }
            }
        }
    }

    getAutoPlayTimestamps = () => {
        this.setState({ autoPlayPopupStart: this.getAutoPlayStartTime() });
        this.setState({ autoPlayPopupEnd: parseInt(this.getAutoPlayStartTime()) + parseInt(this.props.player && this.props.player.autoPlaycountDown && this.props.player.autoPlaycountDown.countdown || 10) })
    }

    resetAutoPlayTimestamps = () => {
        this.setState({ autoPlayPopupStart: null, autoPlayPopupEnd: null });
    }

    onKeyUp = (e) => {
        switch (KeyCode(e.keyCode)) {
            case "LEFT":
            case "RIGHT":
                let self = this;
                clearTimeout(this.seekTimer);
                if (this.state.progressSeek.isTvFocus) {
                    this.seekTimer = setTimeout(function () {
                        if (self.state.seekDuration !== 0)
                            self.handleSeek();
                    }, GLOBALS.PLAY_SEEK_TIMER);
                    break;
                }
        }
        this.scrubbingSpeed = 0;
        this.scrubbingThreshold = 0;
        this._autoHide()
    }

    onClick = (e) => {
        if (this.props.playerState === 'PLAYING') {
            clearInterval(this.timer)
        } else {
            this.setProgress()
        }
    }

    prepareAutoNextEpisodeAsset = () => {
        try {
            setTimeout(() => {
                this.setPlaybackDefault = false
                if (autoPlayEnabled() && shouldEnableAutoPlayToggle() && self.props.player.actionPayload && self.props.player.actionPayload.episode && self.props.player.actionPayload.episode.items[0] && self.props.player.actionPayload.episode.items[0].details) {
                    return self.props.autoPlayNextApi({ genref: self.props.player.actionPayload.episode.items[0].details.genRef, id: self.props.player.actionPayload.episode.items[0].details.id })
                }
                return null
            }, 3000)
            let self = this

        } catch (error) {
            console.log('prepareAutoNextEpisodeAsset() Error', error);
        }

    }

    getAutoPlayCreditTimestamp = () => {
        let video = this.props.player.video
        if (video && video.video.items && video.video.items[0] && video.video.items[0].details.videoAssets && video.video.items[0].details.videoAssets[0] && video.video.items[0].details.videoAssets[0].timeCodes && video.video.items[0].details.videoAssets[0].timeCodes.length > 0) {
            let timeCodes = this.props.player.video.video.items[0].details.videoAssets[0].timeCodes;
            let endCredits = timeCodes.filter(e => e.type === "END_CREDITS");     
            try {
                return parseInt(endCredits[0].startOffsetSeconds)
            } catch (e) {
                return null
            }
        }
        else
            return null
    }

    getAutoPlayStartTime = () => {
        let autoPlaycountDown = this.props.player.autoPlaycountDown.countdown || 10;
        if (this.getAutoPlayCreditTimestamp()) {
            return parseInt(this.getAutoPlayCreditTimestamp());
        } else if (this.getAutoPlayPercentageToSeconds()) {
            if (parseInt((this.props.mediaDuration) - this.getAutoPlayPercentageToSeconds()) > parseInt(autoPlaycountDown)) {
                return this.getAutoPlayPercentageToSeconds();
            } else {
                return parseInt(this.props.mediaDuration) - (parseInt(autoPlaycountDown) + 3);
            }
        } else {
            return parseInt(this.props.mediaDuration) - (parseInt(autoPlaycountDown) + 3);
        }
    }

    getAutoPlayPercentageToSeconds = () => {
        try {
            let autoPlayPercentage = this.props.player.autoPlaycountDown.overlay_display_percentage
            if (parseInt(this.props.mediaDuration)) {
                return Math.round((autoPlayPercentage / 100) * parseInt(this.props.mediaDuration))
            }
        } catch (e) {
            return null
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.navigation);
        window.removeEventListener("keyup", this.onKeyUp);
        document.getElementById('player').removeEventListener("timeupdate", this.playerTimeUpdateEvent)
        if (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE' || localStorage.getItem('PRODUCT_TYPE') === 'EXPLORA_ULTRA') {
            this.saveBookmark(this.props.currentDuration);
        }
        clearTimeout(this.autoHideTimer)
        clearInterval(this.timer)
        this.activeAudio = null //clear active audio variable
        this.playerVariants = null;
        this.setPlaybackDefault = false;
    }

    saveBookmark = (currentTime) => {
        if (this.props.player.actionPayload && this.props.player.actionPayload.type === "PLAY_EPISODE" && currentTime > 0) {
            let activeEpisode = this.props.showpage && this.props.showpage.data && this.props.showpage.data[1] && this.props.showpage.data[1].items.filter(function (e) {
                return e.isTvFocus;
            });
            let CWRow = this.props.editorial && this.props.editorial.data && this.props.editorial.data.filter(item => item.title === 'Continue Watching').pop()
            if (CWRow && CWRow.isTvFocus) {
                let editorialRow = CWRow.items.filter(function (e) {
                    return e.isTvFocus;
                });
                return this.props.saveBookmark({ genref: this.props.player.actionPayload.episode.items[0].details.genRef, timeInSeconds: window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK) > 0 ? window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK) : currentTime })
            }
            if (activeEpisode && activeEpisode[0])
                return this.props.saveBookmark({ genref: this.props.player.actionPayload.episode.items[0].details.genRef, timeInSeconds: window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK) > 0 ? window.localStorage.removeItem(StorageKey.SK_STORED_BOOKMARK) : currentTime })
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (this.props.playbackStarted && !this.setPlaybackDefault && (localStorage.getItem('PRODUCT_TYPE') != 'HISENSE')) {
            this.playerVariants = window.player.getVariantTracks();
            if (this.playerVariants && this.playerVariants.length > 0) {
                this.playerVariants.map(function (variant) {
                    if (variant.language === 'und')
                        variant.language = null
                    if (!variant.label)
                        variant.label = null
                });

                if (alterantiveAudioEnabled()) // check the audio tracks flagr is enabled
                    this.props.updateAudioTracks({ tracks: this.playerVariants }) // prepare the audio track display label list

                filterAndSelectVariant(null, this.props.player, this.playerVariants)
                this.setPlaybackDefault = true
            }
        }
        if (autoPlayEnabled()) {
            let countDown = Math.round(parseInt(this.state.autoPlayPopupEnd) - parseInt(this.currentDuration))
            if (countDown < 5 && !this.props.player.bookmarkCalled) {
                return this.props.saveBookmark(this.currentDuration);
            }
        }
        if ((!this.state.autoPlayPopupStart && this.props.mediaDuration > 0) || ((this.props.mediaDuration > 0) && (prevProps.mediaDuration !== this.props.mediaDuration))) {
            this.getAutoPlayTimestamps();
        }

        if (!Number.isNaN(this.props.mediaDuration) && this.props.mediaDuration > 0) {
            if (this.props.playbackStarted && autoPlayEnabled() && shouldEnableAutoPlayToggle() && this.state.autoPlayPopupStart && this.state.autoPlayPopupEnd) {
                if (!this.state.cancelAutoPlay) {
                    if ((this.props.player.nextEpisode && !this.autoPlayEpisode) || (this.props.player.nextEpisode && this.props.player.nextEpisode.episode && this.props.player.nextEpisode.episode.toString() !== this.autoPlayEpisode && this.autoPlayEpisode[this.autoPlayEpisode.length - 1])) {
                        this.prepareAutoPlayEpisodeMetadata();
                    }

                    if ((this.state.autoPlayPopupStart < this.currentDuration) && (this.currentDuration < this.state.autoPlayPopupEnd)) {
                        if (!this.state.autoPlayNext) {
                            this.setState({ autoPlayNext: true })
                        }
                    } else if (this.currentDuration >= this.state.autoPlayPopupEnd) {
                        if (this.props.player.nextEpisode) {
                            if (this.state.autoPlayNext) {
                                this.setState({ autoPlayNext: false })
                            }
                            if (!this.props.player.autoPlayDataLoading)
                                this.episodeAutoWatchSelect();
                        } else {
                            this.props.stopPlayer();
                        }
                    } else {
                        if (this.state.autoPlayNext) {
                            this.setState({ autoPlayNext: false })
                        }
                    }
                } else {
                    if (this.state.autoPlayNext) {
                        this.setState({ autoPlayNext: false })
                    }
                }
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.player.error === null && nextProps.player.error) {
            this.resetControls()
        }
    }

    goBack = () => {
        if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
            this.handleSettingsNavigation('BACK') // Quality options - navigation
        } else if (this.state.playPause.isTvFocus || this.state.progressSeek.isTvFocus || this.state.settings.isTvFocus) {
            this.props.setPlayerFocus()
            this.hideControls()
        }
        this.setState({ autoPlayNext: false })
    }

    hideControls = () => {
        return this.setState({ ...this.state, playPause: { isTvFocus: false }, progressSeek: { isTvFocus: false }, settings: { isTvFocus: false }, playerControls: false, seeking: false, seekDuration: this.state.seeking ? this.state.seekDuration : 0 })
    }

    showControls = () => {
        this.clearAutoHideTimer()
        clearInterval(this.timer)
        if (this.props.player.isTvFocus)
            this.props.removePlayerFocus()
        return this.setState({ ...this.state, seeking: true, playerControls: true, progressSeek: { isTvFocus: true }, playPause: { isTvFocus: false }, seekDuration: this.state.seekDuration === 0 ? this.currentDuration : this.state.seekDuration })
    }

    resetControls = () => {
        this.clearAutoHideTimer()
        this.setState({ ...this.state, playerControls: false, progressSeek: { isTvFocus: false }, playPause: { isTvFocus: false } })
        this.props.setPlayerFocus()
    }

    _autoHide = () => {
        var self = this
        var duration = GLOBALS.VOD_AUTO_HIDE_DURATION
        this.clearAutoHideTimer()
        this.autoHideTimer = setTimeout(function () {
            self.props.setPlayerFocus()
            self.hideControls()
            self.props.hidePlayerSettings()
        }, duration)
    }

    clearAutoHideTimer = () => {
        if (this.autoHideTimer !== null) {
            clearTimeout(this.autoHideTimer)
        }
    }

    handleUp = () => {
        if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
            this.handleSettingsNavigation('UP') // Quality options - navigation
        } else if (this.state.playPause.isTvFocus || this.state.settings.isTvFocus) {
            this.setState({ ...this.state, progressSeek: { isTvFocus: true }, playPause: { isTvFocus: false }, settings: { isTvFocus: false } })
            this.clearAutoHideTimer()
        } else if (!this.state.playPause.isTvFocus && !this.state.progressSeek.isTvFocus && !this.state.settings.isTvFocus) {
            this.handleDown()
        }
    }

    handleRemoteEvents = (keycode) => {
        this.payload = {
            currentDuration: parseInt(this.currentDuration)
        }
        if (this.props.player.isTvFocus)
            this.props.removePlayerFocus()
        if (keycode === "PLAY") {
            this.props.play(this.payload)
            this.setState({ ...this.state, isPlaying: true, playerControls: true, progressSeek: { isTvFocus: false }, playPause: { isTvFocus: true }, seeking: false, seekDuration: 0 }, () => clearInterval(this.timer))
        } else if (keycode === "PAUSE") {
            this.props.pause(this.payload)
            this.setState({ ...this.state, isPlaying: false, playerControls: true, progressSeek: { isTvFocus: false }, playPause: { isTvFocus: true }, seeking: false, seekDuration: 0 }, () => clearInterval(this.timer))
        } else if ((keycode === "FAST_FWD") && !this.state.seeking) {
            this.showControls()
            this.updateSeekDuration('RIGHT', (this.state.seekDuration + GLOBALS.FAST_FORWARD_LENGTH))
            this.handleRemoteSeek(keycode)
        } else if ((keycode === "REWIND") && !this.state.seeking) {
            this.showControls()
            this.updateSeekDuration('LEFT', (this.state.seekDuration - GLOBALS.REWIND_LENGTH))
            this.handleRemoteSeek(keycode)
        }
    }

    handleRight = () => {
        if (this.state.autoPlayNext) {
            if (this.props.player.nextEpisode) {
                this.autoPlayActions[0].isTvFocus = false
                this.autoPlayActions[1].isTvFocus = true
            } else if (this.props.player.tryThis && this.props.player.tryThis.length > 0) {
                this.props.handleTryThisFocus({ 'move': 'RIGHT' })
            }
        } else {
            if (this.state.playerControls) {
                if (this.state.settings.isTvFocus && (!this.props.player.settings || (this.props.player.settings && !this.props.player.settings.isVisible))) {
                    this.setState({ ...this.state, playPause: { isTvFocus: true }, settings: { isTvFocus: false } })
                } else if (this.state.progressSeek.isTvFocus) {
                    if (!this.state.seeking)
                        this.showControls()
                    this.updateSeekDuration('RIGHT', (this.state.seekDuration + GLOBALS.SEEK_LENGTH))
                }
            }
        }
    }

    handleLeft = () => {
        if (this.state.autoPlayNext) {
            if (this.props.player.nextEpisode) {
                this.autoPlayActions[1].isTvFocus = false
                this.autoPlayActions[0].isTvFocus = true
            } else if (this.props.player.tryThis && this.props.player.tryThis.length > 0) {
                this.props.handleTryThisFocus({ 'move': 'LEFT' })
            }
        } else {
            if (this.state.playerControls) {
                if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
                    this.handleSettingsNavigation('LEFT') // Quality options - navigation
                } else if (this.state.playPause.isTvFocus && (localStorage.getItem('PRODUCT_TYPE') != 'HISENSE')) {
                    this.setState({ ...this.state, settings: { isTvFocus: true, data: this.state.settings.data }, playPause: { isTvFocus: false } })
                } else if (this.state.progressSeek.isTvFocus) {
                    if (!this.state.seeking)
                        this.showControls()
                    this.updateSeekDuration('LEFT', (this.state.seekDuration - GLOBALS.SEEK_LENGTH))
                }
            }
        }
    }

    handleDown = () => {
        this._autoHide()
        if (this.props.player.isTvFocus)
            this.props.removePlayerFocus()
        if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
            this.handleSettingsNavigation('DOWN') // Quality options - navigation
        } else if ((this.state.seekDuration !== this.currentDuration) && !this.state.settings.isTvFocus) {
            this.setState({ ...this.state, progressSeek: { isTvFocus: false }, playerControls: true, playPause: { isTvFocus: true }, seeking: false, seekDuration: 0 }, () => {
                clearInterval(this.timer)
                this.setProgress()
            })
        }
    }

    handleSelect = (keyCode) => {
        if (this.state.autoPlayNext) {
            if (this.props.player.nextEpisode) {
                this.props.player.nextEpisode.overlay = 'Autoplay Next Episode';
                if (this.autoPlayActions[0].isTvFocus) {
                    this.episodeAutoWatchSelect()
                    this.props.player.nextEpisode.buttonText = 'Watch';
                    this.props.WatchNextEpisodeDetails(this.props.player.nextEpisode)
                } else if (this.autoPlayActions[1].isTvFocus) {
                    this.resetAutoPlayTimestamps();
                    this.setState({ ...this.state, cancelAutoPlay: true })
                    this.props.player.nextEpisode.buttonText = 'Cancel';
                    this.props.WatchNextEpisodeDetails(this.props.player.nextEpisode)
                }
            } else if (this.props.player.tryThis && this.props.player.tryThis.length > 0) {
                let focusedCard = this.props.player.tryThis[0];
                // Play content
                if (this.state.playPause.isTvFocus) {
                    this.requestPlayPause()
                    this.setState({ ...this.state, playPause: { isTvFocus: false } }, () => {
                    })
                } else if (!this.state.playPause.isTvFocus)
                    this.onVideoSelected(focusedCard)
            }
        } else {
            if (this.state.progressSeek.isTvFocus) {
                this.handleSeek()
                if (!this.props.player.isTvFocus)
                    this.props.setPlayerFocus()
            } else if (this.state.playPause.isTvFocus) {
                this.requestPlayPause()
            } else if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
                this.handleSettingsOptions(keyCode)
            } else if (this.state.settings.isTvFocus) {
                let payload = {
                    clickedItem: 'settingsCog'
                };
                this.props.playerSettingsFocus(payload, null)
            } else if (!this.state.progressSeek.isTvFocus && !this.state.playPause.isTvFocus && !this.state.settings.isTvFocus) {
                this.handleDown()
            }
            clearTimeout(this.seekTimer);
        }
    }

    episodeAutoWatchSelect = () => {
        this.resetCurrentDuration();
        this.resetAutoPlayTimestamps();
        this.setState({ autoPlayNext: false })
        let CWindex = this.props.editorial.data.findIndex((item) => item.title === 'Continue Watching' && item.isVisible);
        let CWrowindexes = CWindex !== -1 ? this.props.editorial.data[CWindex].items.findIndex((item) => item.isTvFocus) : -1;
        let getAllEpisodesdata = CWrowindexes !== -1 ? this.props.editorial.data[CWindex].items[CWrowindexes].getAllEpisodes : '';
        let focusedButton = (this.props.showpage && this.props.showpage.data && this.props.showpage.data[1]) || { items: this.props.continueWatching && this.props.continueWatching.item && this.props.continueWatching.item.getAllEpisodes ? this.props.continueWatching.item.getAllEpisodes : getAllEpisodesdata }
        let nextEpisode = this.props.player.nextEpisode;
        if (nextEpisode) {
            let currentFocussedItem = []
            focusedButton.items.forEach(item => {
                if (this.props.player.nextEpisode.genRef === item.genRef) {
                    item.isTvFocus = true
                    item.bookmarkTime = null
                    item.details.adRequest = nextEpisode.adRequest
                    item.details.seasonNumber = nextEpisode.season
                    item.details.seasonEpisode = nextEpisode.episode
                    item.details.episode = nextEpisode.episode
                    item.details.genRef = nextEpisode.genRef
                    item.details.id = nextEpisode.id
                    item.details.title = nextEpisode.episode_title
                    item.details.videoAssets[0].url = nextEpisode.url
                    item.details.videoAssets[0].manItemId = nextEpisode.manItemId
                    currentFocussedItem.push(item)
                }
            })
            this.props.player.actionPayload.episode.items = currentFocussedItem
            this.props.resetVodPlaybackStatesforAutoPlay();
            this.props.updateWatchNextEpisodeDetails(this.props.player.actionPayload);
            this.showMetadata();
            this.prepareAutoNextEpisodeAsset();
        }
    }

    onVideoSelected = (data) => {
        this.props.resetVodPlaybackStatesforAutoPlay();
        this.props.stopPlayer();
        this.props.hideEpisodeOverlay();
        if (data && data.items) {
            data.items.forEach(item => {
                if (item.isTvFocus) {
                    this.props.history.push({
                        pathname: item.linkTo,
                        payload: item,
                    });
                    this.props.getEditorials(this.props.history.location.pathname);
                }
            });
            this.prepareAutoNextEpisodeAsset();
        }
    }

    updateSeekDuration = (action, seekLength) => {
        if (action === 'RIGHT') {
            this.setState({ ...this.state, seeking: true, seekDuration: seekLength })
            this.handleScrubbingSpeed()

            if (this.state.seekDuration >= this.props.mediaDuration) {
                this.setState({ ...this.state, seeking: true, seekDuration: this.props.mediaDuration })
            } else if (this.scrubbingSpeed) {
                this.setState({ ...this.state, seeking: true, seekDuration: seekLength * parseInt(this.scrubbingSpeed) })
            }
        } else if (action === 'LEFT') {
            this.setState({ ...this.state, seeking: true, seekDuration: seekLength })
            this.handleScrubbingSpeed()

            if (this.state.seekDuration < 0) {
                this.setState({ ...this.state, seeking: true, seekDuration: 0 })
            } else if (this.scrubbingSpeed && this.scrubbingSpeed > 1) {
                this.setState({ ...this.state, seeking: true, seekDuration: 0 })
            }
        }
        this.setProgressDuration(this.state.seekDuration)
    }

    getProgress = (current, total) => {
        this.setState({ ...this.state, seekPercentage: (100 * current) / total + '%' })
        return this.state;
    }

    setProgress = () => {
        var self = this
        self.getProgress(this.currentDuration, self.props.mediaDuration)
        this.timer = setInterval(function () {
            self.getProgress(this.currentDuration, self.props.mediaDuration)
        }, GLOBALS.GET_PROGRESS_TIMER)
        if (this.currentDuration >= self.props.mediaDuration) {
            clearInterval(this.timer)
        }
    }

    handleSeek = (keycode) => {
        this.seekPayload = {
            'start_position': parseInt(this.currentDuration),
            'end_position': parseInt(this.state.seekDuration)
        }
        this.props.startSeek({ 'seekFrom': this.state.seekDuration, 'seekPayload': this.seekPayload })
        this.props.updatePlaybackState(false)
        this.setState({ ...this.state, isPlaying: true, playerControls: false, progressSeek: { isTvFocus: false }, playPause: { isTvFocus: false }, seeking: false, seekDuration: 0 },
            () => { clearInterval(this.timer); this.setProgress() })
        clearTimeout(this.seekTimer);
        this.props.setPlayerFocus();
    }

    handleRemoteSeek = () => {
        this.seekPayload = {
            'start_position': parseInt(this.currentDuration),
            'end_position': parseInt(this.state.seekDuration)
        }
        this.props.updateCurrentDuration(this.state.seekDuration)
        if (this.state.isPlaying) {
            this.props.startSeek({ 'seekFrom': this.state.seekDuration, 'seekPayload': this.seekPayload })
            this.props.updatePlaybackState(false)
        } else {
            this.props.updateSeek({ 'seekFrom': this.state.seekDuration, 'seekPayload': this.seekPayload })
        }
        this.setState({ ...this.state, playerControls: true, progressSeek: { isTvFocus: false }, playPause: { isTvFocus: true }, seeking: false },
            () => { clearInterval(this.timer); this.setProgress() })
    }

    requestPlayPause = () => {
        this.payload = {
            currentDuration: parseInt(this.currentDuration)
        }
        if (this.state.isPlaying) {
            this.props.pause(this.payload)
            this.setState({ ...this.state, isPlaying: false }, () => clearInterval(this.timer))
        } else {
            this.props.play(this.payload)
            this.setState({ ...this.state, isPlaying: true }, () => { clearInterval(this.timer); this.setProgress() })
        }
    }

    handleSettingsOptions = (keycode) => {
        let mainIndex = this.props.player.settings.data.findIndex(item => item.isActive === true);
        let subIndex = -1;
        if (mainIndex > -1) {
            subIndex = this.props.player.settings.data[mainIndex].items.findIndex(item => item.isTvFocus === true);
            if (this.props.player.settings.data[mainIndex].items[subIndex] && !this.props.player.settings.showHeading && keycode === 'SELECT') {
                if (this.props.player.settings.data[mainIndex].MainHeading === 'Streaming Quality')
                    this.props.setVideoQuality({ currentItem: this.props.player.settings.data[mainIndex].items[subIndex], renderType: 'stream', fromPlayer: true })
                else if (this.props.player.settings.data[mainIndex].MainHeading === 'Audio Language')
                    this.activeAudio = this.props.player.settings.data[mainIndex].items[subIndex].id;
                this.handleSettingsonSelect();
            }
        }
        this.handleSettingsNavigation(keycode, mainIndex, subIndex)
    }


    handleSettingsonSelect = () => {
        //Filter and select the variant by quality and audiotrack
        filterAndSelectVariant(this.activeAudio, this.props.player, this.playerVariants);

        //Reset controls
        this.setState({ ...this.state, settings: { isTvFocus: false } }) //remove settings focus
        this.props.setPlayerFocus() //Remove focus from UI and set to player
        this.hideControls() // hide the iplate and controls
    }

    handleSettingsNavigation = (keycode, mainIndex, subIndex) => {
        let quality = this.props.player.settings.data
            && this.props.player.settings.data[0]
            && this.props.player.settings.data[0].items ? this.props.player.settings.data[0].items.filter(item => item.value === parseInt(localStorage.getItem('QUALITY_STORAGE_BITRATE'))) : []

        let payload = {
            activeAudioLang: this.getLangFromActiveVariant(),
            activeQuality: quality.length && quality[0].classification,
            clickedSection: subIndex > -1 ? 'settingsSubList' : 'settingsMainList',
            clickedItem: mainIndex > -1 && this.props.player.settings.data[mainIndex].MainHeading ? this.props.player.settings.data[mainIndex].MainHeading : ''
        };
        this.props.playerSettingsFocus(payload, keycode)
    }

    getSettingsDefaultValues = () => {
        //Function to prepare the settings default values in main display list.
        let qualityData
        let bitratesData = sessionStorage.getItem('VIDEO_QUALITY_CONF') ? JSON.parse(sessionStorage.getItem('VIDEO_QUALITY_CONF')) : defaultBitrateData
        let defaultQuallity = bitratesData.filter(item => item.default === true)
        let qualityTitle = defaultQuallity[0].classification;  //Default value
        if (localStorage.getItem('QUALITY_STORAGE_BITRATE') && this.props.player.settings && this.props.player.settings.data && this.props.player.settings.data[0] && this.props.player.settings.data[0].items && this.props.player.settings.data[0].items.length) {
            qualityData = this.props.player.settings.data[0].items.filter(item => item.value === parseInt(localStorage.getItem('QUALITY_STORAGE_BITRATE')));
            if (qualityData.length === 0) {
                qualityTitle = this.props.player.settings.data[0].items[0].classification
            } else {
                qualityTitle = qualityData[0].classification
            }
        }

        return [{
            id: 'bitrate',
            value: qualityTitle
        }, {
            id: "audioLanguage",
            value: this.getLangFromActiveVariant()
        }]
    }

    getLangFromActiveVariant = () => {
        let activeAudioTitle;
        let variants = window.player.getVariantTracks();
        let activeVariantIndex = variants.findIndex(variant => variant.active === true);
        if (activeVariantIndex > -1) {
            activeAudioTitle = getLabel(variants[activeVariantIndex])
        }
        return activeAudioTitle
    }

    handleScrubbingSpeed = () => {
        this.scrubbingThreshold++
    }

    showMetadata = () => {
        let showdetails = ''
        let activeEpisode = findActiveEpisode(this.props.player)
        if (activeEpisode) {
            if (activeEpisode.seasonNumber)
                showdetails = setPlayerSeason(activeEpisode.seasonNumber);

            if (activeEpisode.seasonEpisode)
                showdetails += setPlayerEpisode(activeEpisode.seasonEpisode, activeEpisode.seasonNumber) + ' ';
            if (activeEpisode.title)
                showdetails += setPlayerEpisodeTitle(activeEpisode.title, activeEpisode.seasonNumber, activeEpisode.seasonEpisode)

            this.displayHeading = activeEpisode.displayTitle;
            if (showdetails)
                this.displayEpisode = showdetails
        }
    }

    prepareAutoPlayEpisodeMetadata = () => {
        let showdetails = ''
        let showdetailSport = ''
        let nextEpisode = this.props.player.nextEpisode;
        if (nextEpisode) {
            if (nextEpisode.season) {
                showdetails = setPlayerSeason(nextEpisode.season);
            }
            if (nextEpisode.episode) {
                showdetails += setPlayerEpisode(nextEpisode.episode, nextEpisode.season);
                this.autoPlayHeading = nextEpisode.title;
            }
            if (nextEpisode.episode_title && nextEpisode.adRequest.s === "Sport")
                showdetailSport = setPlayerEpisodeTitle(nextEpisode.episode_title)
            if (showdetails) {
                this.autoPlayEpisode = showdetails;
            }
            if (showdetailSport) {
                this.autoPlayEpisode = showdetailSport;
            }
        }
    }

    setProgressDuration = (duration) => {
        return this.getProgress(duration, this.props.mediaDuration)
    }

    prepareAutoPlayDlg = () => {
        return <AutoPlayNextEp
            displayHeading={this.autoPlayHeading}
            displayEpisode={this.autoPlayEpisode}
            actions={this.autoPlayActions}
            autoPlayNext={this.state.autoPlayNext}
            isPlayerError={this.props.player.error}
            playbackStarted={this.props.playbackStarted}
            countDownCounter={Math.round(parseInt(this.state.autoPlayPopupEnd) - parseInt(this.currentDuration))}
            autoPlaycountDown={parseInt(this.props.player.autoPlaycountDown.countdown)}
            notificationOverlayDisplayed={this.props.notificationOverlayDisplayed}
        />
    }

    prepareTryThisDlg = () => {
        if (this.props.player.tryThis && this.props.player.tryThis.length > 0) {
            let autoPlayPercentageValues = this.props.player.autoPlaycountDown.overlay_display_percentage
            this.overlayDisplay = Math.round((autoPlayPercentageValues / 100) * parseInt(this.props.mediaDuration))
            return <TvShowpageRecommendations {...this.props.player.tryThis[0]}
                fromSmartTv={window.isSmartTv ? true : false}
                onPlaySelected={this.onPlaySelected}
                fromPlayer={true}
                styledCarousel={styledEditorial}
                playbackStarted={this.props.playbackStarted}
                autoPlayNext={this.state.autoPlayNext}
                episodes={[]}
                billboard={[]}
                notificationOverlayDisplayed={this.props.notificationOverlayDisplayed}
            />
        }
        return null
    }

    preparePlayerCtrls = () => {
        return <div className={[this.state.playerControls ? styles.ctrls : styles.hide, this.props.player && this.props.player.settings && this.props.player.settings.isVisible ? styles.fadeCtrls : ''].join(' ')}>
            <div class={styles.veil}>
                <div className={[styles.playerUI, this.props.player && this.props.player.settings && this.props.player.settings.isVisible ? styles.fadeUItext : ''].join(' ')}>
                    <div className={styles.heading}>
                        {this.displayHeading ? <div className={[styles.title, ((this.displayHeading === this.displayEpisode) || this.displayEpisode === '') ? styles.noEpisode : ''].join(' ')}> {this.displayHeading} </div> : ''}
                        {this.displayEpisode && (this.displayHeading != this.displayEpisode) ? <div className={styles.episode}> {this.displayEpisode} </div> : ''}
                    </div>
                    <div className={styles.duration}>
                        <div className={styles.currentDuration}> {this.state.seeking ? getDurationHHMMSS(this.state.seekDuration) : getDurationHHMMSS(this.currentDuration)} </div>
                        <div className={styles.totalDuration}> {getDurationHHMMSS(this.props.mediaDuration)} </div>
                    </div>

                    <div className={styles.playerProgressBar}>
                        <div className={(this.state.progressSeek.isTvFocus) ? styles.focus : ''}>
                            <div className={styles.playerProgressBarInner} style={{ width: this.state.seekPercentage }} > </div>
                        </div>
                    </div>
                    <div className={styles.playerControls}>
                        <span />
                        <div className={[styles.settingsVOD, !this.state.settings.isTvFocus ? styles.settingsCustomVOD : '', (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE') ? styles.visibilityHidden : ''].join(' ')} >
                            <div className={[this.state.settings.isTvFocus ? styles.settingsFocus : ''].join(' ')}>
                                {!this.state.settings.isTvFocus ? <img src={SETTINGS_ICON} /> : <img src={SETTINGS_ICON_FOCUS} />}
                            </div>
                        </div>
                        <div className={[(this.state.playPause.isTvFocus) ? styles.focus : '', styles.playPause, styles.vodPlayer].join(' ')} onClick={() => this.onClick()}>
                            <div className={(this.state.playPause.isTvFocus) ? styles.circle : ''}>
                                <img src={this.state.isPlaying ? PAUSE_ICON : PLAY_ICON} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    playerSettingsModal = () => {
        if (!this.props.player.error && !this.props.isLoading && this.props.player.settings && this.props.player.settings.data && this.props.player.settings.data.length) {
            let mainIndex = this.props.player.settings.data.findIndex(item => item.isActive === true)
            if (mainIndex === -1) mainIndex = 0
            let subData = {
                heading: this.props.player.settings.data[mainIndex].MainHeading,
                items: (!this.props.player.settings.showHeading) ? this.props.player.settings.data[mainIndex].items : ''
            }
            return <TvPlayerSettings isVisible={this.props.player.settings.isVisible}
                defaultVallues={this.getSettingsDefaultValues()}
                mainList={this.props.player.settings.showHeading ? this.props.player.settings.data : ''}
                subList={subData}>
            </TvPlayerSettings>
        } else
            return null
    }

    render() {
        let autoPlayNextModal = !this.props.player.error && !this.props.isLoading && this.state.autoPlayNext && this.props.player.nextEpisode && this.prepareAutoPlayDlg()
        let checkTryThis = !this.props.player.error && !this.props.isLoading && this.state.autoPlayNext && !this.props.player.nextEpisode && this.prepareTryThisDlg()
        let playerCtrls = this.preparePlayerCtrls()
        let playerSettingsModal = (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE') ? null : this.playerSettingsModal()
        return (
            <div>
                {playerCtrls}
                {autoPlayNextModal}
                {checkTryThis}
                {playerSettingsModal}
            </div>
        )
    }
}

/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        player: state.player,
        continueWatching: state.continueWatching,
        editorial: state.editorial,
    }
}

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    play: (payload) => dispatch({ type: PLAY, payload }),
    pause: (payload) => dispatch({ type: PAUSE, payload }),
    startSeek: (payload) => dispatch({ type: START_SEEK, payload }),
    updateSeek: (payload) => dispatch({ type: UPDATE_SEEK, payload }),
    removePlayerFocus: (payload) => dispatch({ type: REMOVE_PLAYER_FOCUS, payload }),
    setPlayerFocus: (payload) => dispatch({ type: PLAYER_FOCUS, payload }),
    autoPlayNextApi: (payload) => dispatch({ type: AUTO_PLAY_NEXT_EPISODE, payload }),
    updateWatchNextEpisodeDetails: (payload) => dispatch({ type: 'WATCH_AUTO_NEXT_EPISODE', payload }),
    setVideoQuality: (payload) => dispatch({ type: 'SET_VIDEO_QUALITY', payload }),
    WatchNextEpisodeDetails: (payload) => dispatch({ type: WATCH_NEXT_EPISODE_DETAILS, payload }),
    hideEpisodeOverlay: () => dispatch({ type: 'HIDE_EPISODE_OVERLAY' }),
    handleTryThisFocus: (payload) => dispatch({ type: 'PLAYER_TRY_THIS_FOCUS', payload }),
    getEditorials: (payload) => dispatch({ type: EDITORIALS, payload }),
    openPlayer: (payload) => dispatch({ type: OPEN_PLAYER, payload }),
    notificationOverlayDisplayed: (payload) => dispatch({ type: 'NOTIFICATION_OVERLAY_DISPLAYED', payload }),
    closePlayer: (props) => dispatch({ type: "CLOSE_PLAYER", payload: { key: !props.player.manageDeviceError && props.player.channel && props.player.channel.key, 'episode': props.showpage && props.showpage.data ? props.showpage.data[1] : '' } }),
    saveBookmark: (payload) => dispatch({ type: SAVE_BOOKMARK, payload }),
    playerSettingsFocus: (payload, move) => dispatch({ type: PLAYER_SETTINGS_FOCUS, payload, move }),
    hidePlayerSettings: (payload) => dispatch({ type: PLAYER_SETTINGS_HIDE, payload }),
    updateAudioTracks: (payload) => dispatch({ type: PLAYER_AUDIO_TRACKS, payload })
})

export default connect(mapStateToProps, mapDispatchToProps)(VodPlayerCtrl)
